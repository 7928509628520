




























  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { CombinedFilters, FilteringAttributeType } from '@one/types'
  import {
    CombinedFilterChangeEvent,
  } from '~/utils/ProductsListing'

  @Component({
    components: {
      OneMulticheckboxFilter: () => import('./OneMulticheckboxFilter.vue'),
      OneInputFilter: () => import('./OneInputFilter.vue'),
    },
  })
  export default class OneFiltersContainer extends Vue {
    name: string = 'OneFiltersContainer'

    componentMapping = {
      [FilteringAttributeType.MULTICHECKBOX]: 'one-multicheckbox-filter',
      [FilteringAttributeType.INPUT]: 'one-input-filter',
    }

    @Prop({
      type: Object,
      required: true,
    })
    filters!: Record<string, CombinedFilters>;

    @Prop({
      default: false,
      type: Boolean,
    })
    loading!: boolean;

    @Prop({
      type: String,
      required: true,
    })
    title!: string;

    @Prop({
      type: Boolean,
    })
    isCatalogOpened!: Boolean;

    @Prop({
      type: Boolean,
    })
    isUserLogged!: Boolean;

    @Prop({
      type: Boolean,
      default: false,
    })
    showFilterByPrice!: boolean;

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    hydrationIdle!: boolean;

    mainComponentClass = 'one-filters-container'

    mounted() {
      this.$plugins.onMounted(this.mainComponentClass, this)
    }

    destroyed() {
      this.$plugins.onUnmounted(this.mainComponentClass)
    }

    // TODO: move this logic to oneCore
    hideStockCatalogOpen(filter: CombinedFilters): boolean {
      return !this.isUserLogged && this.isCatalogOpened && filter.nameCode === 'stocks'
    }

    onFilterChanged(event: CombinedFilterChangeEvent, filter: CombinedFilters) {
      this.$emit('filter-changed', {
        ...event,
        nameCode: filter.nameCode,
        type: filter.type,
      })
    }

    get createTestId(): string {
      return this.hydrationIdle ? 'catalog-list:filter-list:list' : ''
    }
  }
